import React from "react";
import { useState } from "react";

import bigShoe from "../../../assets/Icon/bigShoe.svg";
import cushion from "../../../assets/Icon/big-cushion.svg";
import small1 from "../../../assets/Icon/small1.svg";
// import small2 from "../../../assets/Icon/small2.svg";
import small3 from "../../../assets/Icon/small3.svg";
import small4 from "../../../assets/Icon/small4.svg";
import tic from "../../../assets/Icon/tic.svg";
import barcode from "../../../assets/Icon/barcode.svg";
import barcode2 from "../../../assets/Icon/barcode2.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import { useEffect } from "react";
import Loader from "../../CustomCommons/Loader";
import swal from "sweetalert";
import { saveAs } from "file-saver";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { logout_func2 } from "../../Const/logoutCommon";
import ReactHtmlParser from "react-html-parser";

const ViewProduct = ({ pdId }) => {
  const [data, setData] = React.useState("Not Found");
  console.log(pdId, "682");
  const getId = pdId;

  const { id = pdId } = useParams();
  const [pd, setPd] = useState({});
  const history = useHistory();
  const [material, setMaterial] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [colorState, setColorState] = useState("");
  const [sizeState, setSizeState] = useState("");
  const [metState, setMetState] = useState("");
  const [attrVal, setAttrVal] = useState("");
  const [queryState, setQueryState] = useState("");
  const [variantPrice, setVariantPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, Settotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [percentis, setPercentis] = useState(0);
  const [mainPrice, setMainPrice] = useState(0);
  const [mainAttrState, setMainAttrState] = useState([]);
  const [scm, setScm] = useState();
  const [mainState, SetmainState] = useState({});
  const [basePrice, setBasePrice] = useState(0);
  const [barcode, setBarcode] = useState("");
  const [outletStock, setOutletStock] = useState([]);

  const [baseVarPrice, setBaseVarPrice] = useState(0);
  const [OnlogoutHide, setOnlogoutHide] = useState(0);
  // console.log(colorState){
  //   color:{red: false,green:false},
  //   size:{Xl:false,L:false}
  // }

  var options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    // day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  function toDataURL(url) {
    return fetch(url, {
      method: "GET",
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        console.log("blob ", blob);

        return URL.createObjectURL(blob);
      });
  }

  const download = (slug, product_name, barUrl) => {
    // window.open(url);
    const url = `${BASE_URL}/api/v1/inventory/inventory/product_barcode/?slug=${slug}`;
    console.log(barUrl);
    axios
      .get(url)
      .then((res) => {
        var a = document.createElement("a"); //Create <a>
        a.href = "data:image/png;base64," + res.data.data.data; //Image Base64 Goes here
        console.log(a.href);
        a.download = product_name + ".png"; //File name Here
        a.click(); //Downloaded file
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const print = useRef();

  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        margin: 1000;
      }
    }`,
    content: () => print.current,
    // documentTitle: "emp-data",
    bodyClass: "dis",
  });

  const getPd = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/${
      getId ? getId : id
    }`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        console.log("result", result);
        setBarcode(result.barcode);
        setPd(result);
        setMainAttrState(res.data?.data?.variants_json?.mainState);
        const at = res.data?.data?.variants_json?.mainState;

        setAttrVal(res.data?.data?.variants_json?.AttributesInputValue);
        setPrice(Number(result.price));
        setDiscount(Number(result.discount));
        setIsLoading(false);
        console.log(result.slug, "-------------------------slug--------------");
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  const getOutletStock = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/stock-by-outlet/${
      getId ? getId : id
    }`;
    axios
      .get(url)
      .then((res) => {
        const result = res.data.data;
        setOutletStock(result);
        console.log(result, "out");
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        if (
          message === "Invalid token." ||
          JSON.parse(err.request.response).code === 401
        ) {
          // history.push('/login')
          logout_func2(setOnlogoutHide, history);
        }
        // console.log(err.request.response);
      });
  };
  useEffect(() => {
    getPd();
    getOutletStock();
  }, []);

  useEffect(() => {
    var str = "";
    Object.keys(mainAttrState).map(
      (curr, index) => (str += `${index !== 0 ? "-" : ""}${mainState[curr]}`)
    );
    console.log(str);
    let baseprice =
      parseInt(pd?.discount) === 0
        ? parseInt(pd?.price)
        : parseInt(pd?.discount);
    let variantPrice =
      attrVal[str] !== undefined && attrVal[str]["variant_price"];
    let variantStock =
      attrVal[str] !== undefined && attrVal[str]["variant_stock"];
    let total =
      baseprice + parseInt(variantPrice === undefined ? 0 : variantPrice);

    setBasePrice(basePrice);

    setBaseVarPrice(parseInt(variantPrice));
    console.log("variantPrice", variantPrice);
    if (variantPrice === undefined || variantPrice) {
      swal(
        `Variant Name : ${str}`,

        `Baseprice: ( ৳ ${baseprice} ) + Variant Price: ( ৳ ${parseInt(
          variantPrice === undefined ? 0 : variantPrice
        )} ) =  ৳ ${total} \n \n Variant Instock : ${
          parseInt(variantStock) < 0 ? "Unavailable" : variantStock
        }`
        // AnimationEffect:'fadeIn'
      );
    }
  }, [mainState, mainPrice, total, variantPrice]);

  const downloadImage = () => {
    saveAs(barcode, "barcode.png");
    // swal('Barcode Downloaded')
  };

  useState(() => {
    let baseprice =
      parseInt(pd?.discount) === 0
        ? parseInt(pd?.price)
        : parseInt(pd?.discount);
    setMainPrice(Number(baseprice + baseVarPrice));
  }, [baseVarPrice]);

  if (isLoading) {
    return <Loader />;
  }
  console.log("mainState", mainState);

  return (
    <div className="categorey-parent ">
      <div className="row me-2 me-md-0">
        <div className="col-12 col-lg-5 row mt-3 h-auto" style={{}}>
          <div
            className="col-12 d-flex justify-content-center "
            style={{ borderRadius: "10px" }}
          >
            <img
              style={{ borderRadius: "10px" }}
              className="img-fluid w-75 h-75"
              src={pd?.thumb_url ? pd?.thumb_url : cushion}
              alt=""
            />
          </div>
          {/* <div className="col-12 " style={{ borderRadius: "10px" }}>
            <img style={{ borderRadius: "10px", height: "400px" }} className="img-fluid w-100" src={pd?.thumb ? pd?.thumb : cushion} alt="" />
          </div> */}
          <div className="col-12  py-4 d-flex flex-wrap justify-content-around small-mr mt-51">
            {pd?.images?.slice(0, 4)?.map((img) => (
              <div key={img.id} className=" mt-2 rounded">
                <img
                  className="rounded"
                  style={{ maxWidth: "110px", minHeight: "100px" }}
                  src={`${BASE_URL}${img.image}`}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        {/* ======content div========== */}
        <div
          className="col-12 col-lg-7 mt-3 product-content  bg-white"
          style={{
            border: "0.5px solid #E0E0E0",
          }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <div className=" mb-2 ">
                <span style={{ fontSize: "14px", color: "#828282" }}>
                  {pd.category_name}
                </span>
                <button
                  className={`stock px-2 ms-2 ${
                    pd.is_active === true ? "" : "bg-danger"
                  }`}
                  style={{ fontSize: "14px" }}
                >
                  {pd.is_active === true ? "Active" : "Disable"}
                </button>
              </div>

              <h5 style={{ marginBottom: "4px" }}>
                {pd.name?.length > 19 ? `${pd.name}` : pd?.name}
              </h5>

              <span
                className=""
                style={{ fontSize: "13px ", color: "#333333" }}
              >
                Added Details:{" "}
                {new Date(pd.created_at).toLocaleDateString("en-US", options)}
              </span>
            </div>
            <div className="me-4">
              <div
                className=" main-barcode"
                style={{ maxWidth: "350px", minWidth: "200px" }}
              >
                <img
                  ref={print}
                  // className="img-fluid"
                  style={{
                    height: "115px",
                    width: "100%",
                    objectFit: "contain",
                  }}
                  src={pd?.barcode}
                  alt=""
                />
                {/* <img ref={print} className="img-fluid"   src={pd?.barcode} alt="" /> */}
                <div className="btn-barcode">
                  <button
                    className="print-btn "
                    onClick={() => {
                      download(pd?.slug, pd?.name, pd?.barcode);
                    }}
                  >
                    Print
                  </button>

                  {/* <a href={pd?.barcode + "?force=true"} download className="print-btn " >Print</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center my-2">
            <p className="my-auto me-2">{pd.stock} Pcs</p>
            <button
              className={`${pd.stock > 0 ? " stock px-1" : " stock2 px-1"}`}
            >
              {pd.stock > 0 ? "in Stock" : "Stock Out"}
            </button>
          </div>
          <div className="mt-3 d-flex align-items-center">
            <span className="fw-bold">SKU : </span>{" "}
            <span className="ms-2">{pd?.sku}</span>
          </div>

          <div className="mt-3">
            <span
              style={{
                fontSize: "14px",
                color: "#828282",
                marginBottom: "8px",
              }}
            >
              Price:
            </span>

            <h5 className="price-head">
              ৳{pd?.price ? parseInt(pd?.price) : "0"}
            </h5>

            {/* {parseInt(pd.discount) === 0 ? (
              <h5 className="price-head">
                ৳{pd?.price ? parseInt(pd?.price) : "599"}
              </h5>
            ) : (
              <h5 className="price-head">
                ৳ {pd?.discount ? parseInt(pd?.discount) : "599"}
                <sup className="sup ms-1">
                  ৳ {pd?.price ? parseInt(pd?.price) : "620"}
                </sup>
                <span className=" off ms-2">
                  {parseInt(
                    (((pd?.price - pd?.discount) / pd?.price) * 100).toFixed()
                  )}{" "}
                  % Off
                </span>
              </h5>
            )} */}
          </div>

          {/* <div className="row mt-4">
            {mainAttrState &&
              Object.keys(mainAttrState).map((a, index) => (
                <div key={a} className="col-12  mt-4">
                  <p>{a}</p>
                  <div className="d-flex flex-wrap ">
                    {Object.values(mainAttrState) ? (
                      Object.values(mainAttrState)[index]?.map((s) => (
                        <button
                          key={s}
                          className={`size-btn px-4 mx-1 ${
                            mainState[a] === s ? "size-btn-click" : ""
                          }`}
                          value={s}
                          onClick={(e) => {
                            SetmainState({ ...mainState, [a]: s });
                          }}
                        >
                          {s}
                        </button>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              ))}
          </div> */}
          <div className="my-3">
            <h5 className="my-2 text-success text-center">Variant Details</h5>
            {pd.variant_sold_details?.map((data) => (
              <div className="d-flex justify-content-between my-1">
                <div>
                  <h5>Variant Name</h5>
                  <p>{data?.name}</p>
                </div>
                <div>
                  <h5>Total Sold</h5>
                  <p>{data?.total_sold_quantity}</p>
                </div>
                <div>
                  <h5>Total Stock</h5>
                  <p>{data?.stock}</p>
                </div>
                <div>
                  <h5>Price</h5>
                  <p>{data?.variant_price}</p>
                </div>
              </div>
            ))}
          </div>
          {outletStock?.length > 0 ? (
            <>
              <div className="my-3">
                <h5 className="my-2 text-success text-center">Outlet Stock</h5>
                <div>
                  <div className="d-flex justify-content-evenly my-1">
                    <div>
                      <h5>Outlet Name</h5>
                    </div>
                    <div>
                      <h5>Outlet Stock</h5>
                    </div>
                  </div>
                </div>
                {outletStock?.map((data, index) => (
                  <div className="d-flex justify-content-evenly my-1">
                    <div style={{width:'50%'}}>
                      <p className="text-center">{data?.outlet_name}</p>
                    </div>
                    <div style={{width:'50%'}}>
                      <p className="text-center">{data?.stock}</p>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {/* ========description======= */}
          <div className="my-4">
            <p>Description</p>
            <span className="my-4" style={{ color: "#828282" }}>
              {ReactHtmlParser(pd?.description)}
            </span>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProduct;
